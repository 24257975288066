<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'reports' }"
          >Reports</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4" v-if="reportDetail">
      <div class="col-md-6 col-sm-12 col-xs-12 my-6">
        <v-form ref="form" class="report-form">
          <v-divider></v-divider>
          <h3 class="py-5 primary--text">Report Details</h3>
          <br />

          <v-card class="detail-wrapper py-5 px-6">
            <div class="mb-4">
              <label class="text-field-label">Report Type: </label>
              <span>{{ reportDetail.report_type }}</span>
            </div>
            <div class="mb-4">
              <label class="text-field-label">Reporter: </label>
              <user-avatar
                :avatar="report.reporter.avatar"
                size="30"
                class="mr-2"
              />
              <router-link
                :to="{
                  name: 'user.details',
                  params: { id: reportDetail.reporter.id },
                }"
                >{{ reportDetail.reporter.full_name }}</router-link
              >
            </div>
            <div class="mb-4">
              <label class="text-field-label">Category: </label>
              <span>{{
                reportDetail.reason ? reportDetail.reason.label : ''
              }}</span>
            </div>
            <div class="mb-4">
              <label class="text-field-label">Description: </label>
              <span>{{ reportDetail.description }}</span>
            </div>
            <div class="mb-4">
              <label class="text-field-label">{{ reportableLabel }}: </label>
              <v-divider></v-divider>
              <v-card
                solo
                class="detail-wrapper py-5 px-6"
                v-if="reportDetail.report_type === 'users'"
              >
                <div class="mb-4">
                  <label class="text-field-label">User: </label>
                  <user-avatar
                    :avatar="reportDetail.reportable.avatar"
                    size="30"
                    class="mr-2"
                  />
                  <router-link
                    :to="{
                      name: 'user.details',
                      params: { id: report.reportable.id },
                    }"
                    >{{ reportDetail.reportable.full_name }}</router-link
                  >
                </div>
              </v-card>
              <v-card
                solo
                class="detail-wrapper py-5 px-6"
                v-else-if="reportDetail.report_type === 'bookings'"
              >
                <div class="mb-4">
                  <label class="text-field-label">Booked item: </label>
                  <!-- to do: booking details page; redirect to booking details -->
                  <router-link
                    :to="{
                      name: 'booking.edit',
                      params: { id: report.reportable.id },
                    }"
                    >{{ reportDetail.reportable.listing.title }}
                  </router-link>
                </div>

                <div class="mb-4 inline-flex">
                  <label class="text-field-label"
                    >Booking period:
                    {{ formatDate(reportDetail.reportable.start_date) }} -
                    {{ formatDate(reportDetail.reportable.end_date) }}</label
                  >
                </div>
              </v-card>
              <v-card
                solo
                class="detail-wrapper py-5 px-6"
                v-else-if="reportDetail.report_type === 'listings'"
              >
                <div class="mb-4">
                  <label class="text-field-label">Listing:</label>
                  <router-link
                    :to="{
                      name: 'listing.edit',
                      params: { id: report.reportable.id },
                    }"
                    >{{ reportDetail.reportable.title }}</router-link
                  >
                </div>
                <div class="mb-4">
                  <v-row>
                    <v-col
                      class="d-flex child-flex"
                      cols="4"
                      sm="4"
                      v-for="photo in reportDetail.reportable.photos"
                      :key="photo.id"
                    >
                      <v-img
                        :lazy-src="photo.responsive_url"
                        :src="photo.responsive_url"
                        gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                        aspect-ratio="1"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row> </template
                      ></v-img>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </div>
            <div class="mb-4">
              <label class="text-field-label">Attachments: </label>
              <v-divider></v-divider>
              <v-card solo class="detail-wrapper py-5 px-6">
                <div
                  class="mb-4"
                  v-for="attachment in reportDetail.attachments"
                  :key="attachment.id"
                >
                  <v-img
                    :lazy-src="attachment.responsive_url"
                    max-height="150"
                    max-width="250"
                    :src="attachment.responsive_url"
                  ></v-img>
                </div>
                <div v-if="reportDetail.attachments.length <= 0">
                  No available attachments
                </div>
              </v-card>
            </div>
          </v-card>
        </v-form>
      </div>
    </div>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>

    <v-overlay absolute :value="isLoading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import {
  mdiChevronLeft,
  mdiCheck,
  mdiAlertCircleOutline,
  mdiDelete,
} from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import UserAvatar from '@/components/UserAvatar'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default {
  name: 'ReportDetails',

  components: {
    UserAvatar,
    AppBarNavIcon,
  },

  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
        delete: mdiDelete,
      },

      reportDetail: null,
      isLoading: false,
      loading: false,
      isLoadingStatus: false,
      formMode: this.$route.params.id === 'new' ? 'new' : 'details',
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
    }
  },

  computed: {
    ...mapState({
      report: (state) => state.report.reportDetails,
      users: (state) => state.user.list,
      categories: (state) => state.category.list,
    }),

    displayName() {
      return 'Report Detail'
    },

    disableMessage() {
      const action = this.user && this.user.blocked_at ? 'unblock' : 'block'
      return `Are you sure you want ${action} this account?`
    },

    listingStatus() {
      return this.reportDetail.reportable.is_disabled ? 'Disabled' : 'Enabled'
    },

    listingStatusLabel() {
      return this.reportDetail.reportable.is_disabled ? 'Disable' : 'Enable'
    },

    reportableLabel() {
      switch (this.reportDetail.report_type) {
        case 'users':
          return 'User Details'
        case 'bookings':
          return 'Booking Details'
        case 'listings':
          return 'Listing Details'
        default:
          return 'Reportable'
      }
    },
  },

  methods: {
    ...mapActions({
      getReportDetails: 'report/getReportDetails',
      updateToolStatus: 'tool/updateToolStatus',
      disableListing: 'listing/disableListing',
    }),

    ...mapMutations({
      clearReportDetails: 'report/clearReportDetails',
    }),

    async getReport() {
      this.isLoading = true
      this.clearReportDetails()
      await this.getReportDetails(this.$route.params.id)
      this.reportDetail = this.report
      this.isLoading = false
    },

    async toggleListingStatus() {
      await this.disableListing({
        id: this.reportDetail.reportable.id,
        action: this.reportDetail.reportable.is_disabled ? 'enable' : 'disable',
        reason: '',
      }).then(() => {
        this.getReportDetails(this.$route.params.id)
      })

      this.showSnackbar('Successfully updated listing status', 'success')
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    getListingStatusLabel(isDisabled) {
      return isDisabled ? 'enable' : 'disable'
    },

    async initiate() {
      this.isLoading = true

      this.clearReportDetails()

      if (this.formMode === 'details') {
        this.getReport()
      } else {
        this.clearReportDetails()
        this.isLoading = false
      }
    },

    formatDate(date) {
      return dayjs.utc(date).local().format('MMM DD, YYYY')
    },
  },

  created() {
    this.initiate()
  },

  watch: {
    $route() {
      this.getReport()
    },
  },
}
</script>
